import { Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoute from "./Components/ProtectedRoute";
import SoftLog from "./Components/SoftLog";
import Sidebar from "./Components/Sidebar";
import Dashboard from "./Components/Dashboard/Dashboard";
import Settings from "./Components/Settings/Settings";
import Instructors from "./Components/Instructors/Instructors";
import InstructorProfile from "./Components/InstructorProfile/InstructorProfile";
import InstructorLessonList from "./Components/InstructorLessons/InstructorLessonList";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import PaymentPage from "./Components/PaymentPage/PaymentPage";
import Calendar from "./Components/Calendar/Calendar";
import FilePage from "./Components/FilePage/FilePage";
import CartPage from "./Components/CartPage/CartPage";
import ConversationsPage from "./Components/Messages/ConversationsPage";
import MessagesPage from "./Components/Messages/MessagesPage";
import HistoryPage from "./Components/HistoryPage/HistoryPage";
import { useEffect } from "react";
import ManageCardsPage from "./Components/ManageCardsPage/ManageCardsPage";
import VideoCall from "./Components/VIdeoCall/VideoCall";

function App() {
	useEffect(() => {
		window.addEventListener("error", (e) => {
			if (
				e.message ===
				"ResizeObserver loop completed with undelivered notifications."
			) {
				const resizeObserverErrDiv = document.getElementById(
					"webpack-dev-server-client-overlay-div"
				);
				const resizeObserverErr = document.getElementById(
					"webpack-dev-server-client-overlay"
				);
				if (resizeObserverErr) {
					resizeObserverErr.setAttribute("style", "display: none");
				}
				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute("style", "display: none");
				}
			}
		});
	}, []);
	const routes = (
		<>
			{/* Protected Routes */}
			<Route path="/" element={<Navigate replace to="/dashboard" />} />
			<Route
				path="dashboard"
				element={
					<ProtectedRoute>
						<Sidebar />
						<Dashboard />
					</ProtectedRoute>
				}
			/>
			<Route
				path="video"
				element={
					// <ProtectedRoute>
					<VideoCall />
					// </ProtectedRoute>
				}
			/>
			<Route
				path="files"
				element={
					<ProtectedRoute>
						<Sidebar />
						<FilePage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="files/:folderId"
				element={
					<ProtectedRoute>
						<Sidebar />
						<FilePage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="files/shared"
				element={
					<ProtectedRoute>
						<Sidebar />
						<FilePage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="files/shared/:folderId"
				element={
					<ProtectedRoute>
						<Sidebar />
						<FilePage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="message"
				element={
					<ProtectedRoute>
						<Sidebar />
						<ConversationsPage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="message/:conversationId"
				element={
					<ProtectedRoute>
						<Sidebar />
						<MessagesPage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="history"
				element={
					<ProtectedRoute>
						<Sidebar />
						<HistoryPage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="payment"
				element={
					<ProtectedRoute>
						<Sidebar />
						<PaymentPage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="settings"
				element={
					<ProtectedRoute>
						<Sidebar />
						<Settings />
					</ProtectedRoute>
				}
			/>
			<Route
				path="cards"
				element={
					<ProtectedRoute>
						<Sidebar />
						<ManageCardsPage />
					</ProtectedRoute>
				}
			/>
			<Route
				path="calendar"
				element={
					<ProtectedRoute>
						<Sidebar />
						<Calendar />
					</ProtectedRoute>
				}
			/>
			<Route
				path="cart"
				element={
					<ProtectedRoute>
						<Sidebar />
						<CartPage />
					</ProtectedRoute>
				}
			/>
			{/* Instructor Profile Routes */}
			<Route
				path="instructors"
				element={
					<SoftLog>
						<Sidebar />
						<Instructors />
					</SoftLog>
				}
			/>
			<Route
				path="instructors/:instructorId"
				element={
					<SoftLog>
						<Sidebar />
						<InstructorProfile />
					</SoftLog>
				}
			/>
			<Route
				path="instructors/:instructorId/schedule"
				element={
					<SoftLog>
						<Sidebar />
						<InstructorLessonList />
					</SoftLog>
				}
			/>
			<Route
				path="*"
				element={
					<>
						<ErrorPage />
					</>
				}
			/>
		</>
	);

	return (
		<>
			<Routes>{routes}</Routes>
		</>
	);
}

export default App;
