type Props = {
	id: string;
	expMonth: number;
	expYear: number;
	brand: string;
	last4: string;
	deletePaymentMethod: Function;
};
function Card(props: Props) {
	function deleteHandler() {
		props.deletePaymentMethod(props.id);
	}

	return (
		<li>
			{props.brand} ending in {props.last4}
			<br />
			Expires {props.expMonth}/{props.expYear}
			<button onClick={deleteHandler}>Delete</button>
		</li>
	);
}

export default Card;
