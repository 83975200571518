import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from "@stripe/react-stripe-js";
import { StripeElementChangeEvent } from "@stripe/stripe-js";
import { FormEvent, useEffect, useState } from "react";
import PaymentService from "../../services/payment.service";
import LoadingElement from "../LoadingElement/LoadingElement";

type Props = {
	exitCallback: Function;
};
function NewCardSetup(props: Props) {
	const stripe = useStripe();
	const elements = useElements();
	const [cardComplete, setCardComplete] = useState(false);
	const [cvcComplete, setCvcComplete] = useState(false);
	const [expiryComplete, setExpiryComplete] = useState(false);
	const [cardError, setCardError] = useState("");
	const [cardFocus, setCardFocus] = useState(false);
	const [expFocus, setExpFocus] = useState(false);
	const [cvcFocus, setCvcFocus] = useState(false);
	const [submitHandler, setSubmitHandler] = useState(false);
	const [loading, setLoading] = useState(true);
	const [setupIntent, setSetupIntent] = useState<any>(undefined);

	useEffect(() => {
		setLoading(true);
		createSetupIntent();
		setLoading(false);
	}, []);

	async function createSetupIntent() {
		const result = await PaymentService.getSetupIntent();
		setSetupIntent(result);
		console.log(result);
	}

	const ELEMENT_STYLES = {
		base: {
			color: "#32325D",
			fontWeight: 500,
			fontFamily: "Roboto, Sans-Serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			padding: "10px 5px",

			borderRadius: "10px",
			"::placeholder": {
				color: "#CFD7DF",
			},

			":-webkit-autofill": {
				color: "#e39f48",
			},
		},
		invalid: {
			color: "#E25950",

			"::placeholder": {
				color: "#FFCCA5",
			},
		},
	};

	const ELEMENT_CLASSES = {
		focus: "focused",
		empty: "empty",
		invalid: "invalid",
	};

	const OPTIONS = {
		style: ELEMENT_STYLES,
		classes: ELEMENT_CLASSES,
	};

	function onChangeHandler(event: StripeElementChangeEvent) {
		let tempCardComplete = cardComplete;
		let tempExpiryComplete = expiryComplete;
		let tempCvcComplete = cvcComplete;

		switch (event.elementType) {
			case "cardNumber":
				setCardComplete(event.complete);
				tempCardComplete = event.complete;
				break;
			case "cardExpiry":
				setExpiryComplete(event.complete);
				tempExpiryComplete = event.complete;
				break;
			case "cardCvc":
				setCvcComplete(event.complete);
				tempCvcComplete = event.complete;
				break;
		}

		if (tempCardComplete && tempExpiryComplete && tempCvcComplete)
			setSubmitHandler(true);
		else setSubmitHandler(false);

		event.error ? setCardError(event.error.message) : setCardError("");
	}

	async function submit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return null;
		}
		const { error } = await stripe.confirmCardSetup(setupIntent.client_secret, {
			payment_method: { card: elements.getElement(CardNumberElement)! },
		});
		if (error) {
			setLoading(false);
			setCardError(error.message ?? "Something went wrong. Try again.");
		} else {
			props.exitCallback();
		}
	}
	return (
		<form id="newCardForm" onSubmit={submit}>
			{loading && <LoadingElement size={2} />}
			<div className={loading ? "hidden" : ""}>
				<div id="inputs">
					<div>
						<label htmlFor="cardDiv">Card</label>
						<CardNumberElement
							options={OPTIONS}
							className={cardFocus ? "divInput focused" : "divInput"}
							id="cardDiv"
							onChange={onChangeHandler}
							onFocus={() => setCardFocus(true)}
							onBlur={() => setCardFocus(false)}
						/>
					</div>
					<div className="shortInput">
						<label htmlFor="expirationDateDiv">Expiration Date</label>
						<CardExpiryElement
							options={OPTIONS}
							className={
								expFocus ? "divInput shortInput focused" : "divInput shortInput"
							}
							id="expirationDateDiv"
							onChange={onChangeHandler}
							onFocus={() => setExpFocus(true)}
							onBlur={() => setExpFocus(false)}
						/>
					</div>
					<div className="shortInput">
						<label htmlFor="cvcDiv">CVC</label>
						<CardCvcElement
							options={OPTIONS}
							className={
								cvcFocus ? "divInput shortInput focused" : "divInput shortInput"
							}
							id="cvcDiv"
							onChange={onChangeHandler}
							onFocus={() => setCvcFocus(true)}
							onBlur={() => setCvcComplete(false)}
						/>
					</div>

					<p className="cardError">{cardError}</p>
				</div>
				<div className="nextButtonDiv">
					<button
						type="submit"
						id="dateTimeNext"
						className={submitHandler ? "nextButton" : "nextButton deactivated"}
						disabled={!submitHandler}
					>
						Add payment method
					</button>
				</div>
			</div>
		</form>
	);
}

export default NewCardSetup;
