import { PaymentMethod } from "@stripe/stripe-js";
import axios from "axios";
import config from "../utils/config";
import DeletePaymentMethodRequest from "../Types/DeletePaymentMethodRequest";

class PaymentService {
	static async createPaymentIntent(): Promise<Object> {
		return (
			await axios.get(config.API_URL + "/api/payment/create-payment-intent", {
				withCredentials: true,
			})
		).data;
	}
	static async createPaymentIntentSavedCard(
		method: string | PaymentMethod | null
	): Promise<Object> {
		return (
			await axios.post(
				config.API_URL + "/api/payment/create-payment-intent/saved",
				{ cardId: method },
				{
					withCredentials: true,
				}
			)
		).data;
	}
	static async getPaymentMethods(): Promise<Object> {
		return (
			await axios.get(config.API_URL + "/api/payment/payment-methods", {
				withCredentials: true,
			})
		).data;
	}

	static async getSetupIntent() {
		return (
			await axios.get(config.API_URL + "/api/payment/create-setup-intent", {
				withCredentials: true,
			})
		).data;
	}

	static async deletePaymentMethod(req: DeletePaymentMethodRequest) {
		return (
			await axios.post(
				config.API_URL + "/api/payment/payment-methods/delete",
				req,
				{
					withCredentials: true,
				}
			)
		).status;
	}

	static async paymentComplete(
		intent: string,
		method?: string | PaymentMethod | null
	): Promise<Object> {
		return await axios
			.post(
				config.API_URL + "/api/payment/complete",
				{
					intentId: intent,
					method: method,
				},
				{
					withCredentials: true,
				}
			)
			.catch((err) => err.response);
	}
}

export default PaymentService;
