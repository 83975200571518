import { ChangeEvent, FormEventHandler, useContext, useState } from "react";
import StudentContext from "../../../../Contexts/StudentContext";
import InstrumentType from "../../../../Types/InstrumentType";
import SettingsType from "../../../../Types/SettingsType";
import "../../Settings.scss";
import SelectInstruments from "./SelectInstruments";

type Props = {
	settings: SettingsType;
	setSettings: Function;
	instruments: InstrumentType[];
	userInstruments: Map<number, boolean>;
	setUserInstruments: Function;
	file?: File;
	setFile: Function;
	onSubmitHandler: FormEventHandler<HTMLFormElement>;
};
export default function SettingsForm(props: Props) {
	const [selectInstrumentsModalOpen, setSelectInstrumentsModalOpen] =
		useState(false);
	const user = useContext(StudentContext);

	function selectInstrumentsModalOpenHandler() {
		setSelectInstrumentsModalOpen(!selectInstrumentsModalOpen);
	}

	function onChangeHandler(
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) {
		const newData = {
			...props.settings,
			[event.target.name]: event.target.value,
		};
		switch (event.target.name) {
			case "displayEmail":
				newData[event.target.name] = !props.settings.displayEmail;
				break;
		}
		props.setSettings(newData);
	}

	function onFileChangeHandler(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.files && e.target.files.length > 0)
			props.setFile(e.target.files[0]);
	}

	return (
		<form
			onSubmit={props.onSubmitHandler}
			id="profileForm"
			action=""
			method="POST"
			encType="multipart/htmlForm-data"
		>
			<label htmlFor="name">Name</label>
			<br />
			<input
				type="text"
				name="firstName"
				value={props.settings.firstName}
				onChange={onChangeHandler}
				placeholder="First Name"
				required
			/>
			<input
				type="text"
				name="lastName"
				value={props.settings.lastName}
				onChange={onChangeHandler}
				placeholder="Last Name"
				required
			/>

			{!!!user?.isInstructor && (
				<a id="manage-cards-link" href="/cards">
					Manage saved cards
				</a>
			)}

			{!!user?.isInstructor && (
				<>
					<label htmlFor="file">Profile Picture</label>
					<br />

					{/* <div className="uploadFileWrapper">
                        <button type="button" className="fileUploadButton">Upload New Picture</button>
                        <input value={props.file} onChange={onFileChangeHandler} id='file' name='file' type='file' accept='image/*, .jpg' />
                    </div> */}

					<input
						className="custom-file-input"
						onChange={onFileChangeHandler}
						id="file"
						name="file"
						type="file"
						accept="image/*, .jpg"
					/>
					{props.file && <p>{props.file.name}</p>}
					<br />

					<p>Image will be displayed as a circle.</p>

					<label htmlFor="bio">Biography</label>
					<br />
					<textarea
						onChange={onChangeHandler}
						defaultValue={props.settings.bio}
						name="bio"
						cols={30}
						rows={10}
						placeholder="Bio"
						required
						maxLength={500}
					></textarea>
					<br />

					<label htmlFor="price">Price</label>
					<br />
					<input
						onChange={onChangeHandler}
						type="number"
						name="price"
						placeholder="40.00"
						min="40.00"
						max="999.99"
						step="0.01"
						value={props.settings.price}
						required
					/>
					<br />

					<div className="selectInstrumentsButtonDiv">
						<label htmlFor="selectInstrumentsButton">Instruments</label>
						<br />
						<button
							onClick={selectInstrumentsModalOpenHandler}
							id="selectInstrumentsButton"
							className=""
							type="button"
						>
							Select Instruments
						</button>
						{selectInstrumentsModalOpen && (
							<SelectInstruments
								instruments={props.instruments}
								userInstruments={props.userInstruments}
								setUserInstruments={props.setUserInstruments}
								exitCallback={selectInstrumentsModalOpenHandler}
							/>
						)}
					</div>

					<br />
					<br />

					<a href="/instructor-dash?stripeAccount=true">
						View and Update Payment Details on Stripe
					</a>
					<br />

					<input
						type="checkbox"
						name="displayEmail"
						id="displayEmail"
						checked={props.settings.displayEmail}
						onChange={onChangeHandler}
					/>

					<label htmlFor="displayEmail">Display Email on Profile</label>
					<br />
				</>
			)}
			<div className="submitWrapper">
				<input type="submit" value="Save Changes" className="greenButton" />
			</div>
		</form>
	);
}
