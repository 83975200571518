import xIcon from "../../../../images/x-icon.png";
import { useNavigate } from "react-router-dom";

type Props = {
	id: number;
	title: string;
	description: string;
	linkText: string;
	route: string;
	handleRemove: Function;
	lessonModalHandler: Function;
};
export default function DashboardNotification(props: Props) {
	const ADD_NOTES_TEXT = "Add Notes";
	let navigate = useNavigate();

	function onClickHandler(): void {
		props.handleRemove(props.id);
	}

	function routeNotification(): void {
		onClickHandler();
		navigate(props.route);
	}

	async function openLessonDetails(): Promise<void> {
		if (props.linkText === ADD_NOTES_TEXT) {
			props.handleRemove(props.id);
			props.lessonModalHandler(props.route);
		}
	}

	return (
		<div className="notificationDiv" data-notification-id={props.id}>
			<li key={props.id} className="notificationObject">
				<div className="notificationContent">
					<h5>{props.title}</h5>
					<p>{props.description}</p>
					{props.linkText !== "Add Notes" && (
						<p
							className="notificationLinkText hover"
							onClick={routeNotification}
						>
							{props.linkText}
						</p>
					)}
					{props.linkText === "Add Notes" && (
						// Onclick should delete the lesson and pop up lesson details to input lesson notes
						<>
							<p className="notificationLinkText" onClick={openLessonDetails}>
								{props.linkText}
							</p>
						</>
					)}
				</div>
				{/* Delete Notifcation */}
				<p className="notificationIconWrapper" onClick={onClickHandler}>
					<img src={xIcon} alt="" className="xicon" />
				</p>
			</li>
			<hr className="notificationHr" />
		</div>
	);
}
