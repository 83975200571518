import { useEffect, useState } from "react";
import Card from "./Card";
import "./ManageCardsPage.scss";
import PaymentService from "../../services/payment.service";
import LoadingElement from "../LoadingElement/LoadingElement";
import GreenButton from "../Buttons/GreenButton/GreenButton";
import AddPaymentMethodModal from "./AddPaymentMethodModal";

function ManageCardsPage() {
	const [paymentMethods, setPaymentMethods] = useState<any[]>();
	const [loading, setLoading] = useState(true);
	const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] =
		useState(false);
	useEffect(() => {
		getPaymentMethods();
	}, []);

	async function getPaymentMethods() {
		setLoading(true);
		var methods: any = await PaymentService.getPaymentMethods();
		setPaymentMethods(methods.paymentMethod);
		setLoading(false);
	}

	async function deletePaymentMethod(id: string) {
		setLoading(true);
		const request = {
			paymentMethod: id,
		};

		await PaymentService.deletePaymentMethod(request);
		await getPaymentMethods();
		setLoading(false);
	}

	function paymentModalHandler() {
		setShowAddPaymentMethodModal(!showAddPaymentMethodModal);
		getPaymentMethods();
	}

	return (
		<div id="manageCardsMain" className="gridContainer">
			<div className="fullScreen">
				{loading && <LoadingElement size={4} />}
				{showAddPaymentMethodModal && (
					<AddPaymentMethodModal exitCallback={paymentModalHandler} />
				)}
				{!loading && (
					<>
						<div className="header">
							<h2>Manage Cards</h2>
							<div className="rightSideButtons">
								<GreenButton
									text="Add payment method"
									onClick={paymentModalHandler}
								/>
							</div>
						</div>
						<hr />

						{paymentMethods?.length === 0 && (
							<p className="noContentToShow">
								You have no payment methods saved
							</p>
						)}
						<ul className="paymentMethods">
							{paymentMethods?.map((method) => {
								return (
									<Card
										key={method.id}
										id={method.id}
										expMonth={method.card.exp_month}
										expYear={method.card.exp_year}
										brand={method.card.display_brand}
										last4={method.card.last4}
										deletePaymentMethod={deletePaymentMethod}
									/>
								);
							})}
						</ul>
					</>
				)}
			</div>
		</div>
	);
}

export default ManageCardsPage;
