import { loadStripe } from "@stripe/stripe-js";
import Modal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader/ModalHeader";
import { Elements } from "@stripe/react-stripe-js";
import config from "../../utils/config";

import NewCardSetup from "./NewCardSetup";

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

type Props = {
	exitCallback: Function;
};
function AddPaymentMethodModal(props: Props) {
	return (
		<Modal exitCallback={props.exitCallback}>
			<ModalHeader headerText="Add payment method" />
			<hr />
			<Elements stripe={stripePromise}>
				<NewCardSetup exitCallback={props.exitCallback} />
			</Elements>
		</Modal>
	);
}

export default AddPaymentMethodModal;
