import React, { useRef, useEffect, useState } from "react";
import DailyIframe, { DailyCall } from "@daily-co/daily-js";
import styled from "styled-components";

const CALL_OPTIONS = {
	iframeStyle: {
		width: "100%",
		height: "100%",
		border: "1px solid #e6eaef",
		borderRadius: "6px 6px 0 0",
	},
	showLeaveButton: true,
	showFullscreenButton: true,
	url: "",
	//   showLocalVideo: false,
	//   showParticipantsBar: false,
};

const VideoCall = () => {
	const videoRef = useRef<any>();
	const [callframe, setCallframe] = useState<DailyCall | null>(null);

	useEffect(() => {
		if (!videoRef || !videoRef?.current || callframe) return;
		CALL_OPTIONS.url = "https://cmslessons.daily.co/YQ06qVKZy7X6zg6IT1dm";

		const newCallframe = DailyIframe.createFrame(
			videoRef.current,
			CALL_OPTIONS
		);

		newCallframe.join().then(() => {
			setCallframe(newCallframe);
		});
	}, [callframe, videoRef]);

	return (
		<div>
			{/* <Header>Welcome to our webinar!</Header> */}
			<VideoContainer>
				<Callframe ref={videoRef} />
			</VideoContainer>
		</div>
	);
};

const VideoContainer = styled.div`
	margin: auto;
	max-width: 100vw;
	height: 100vh;
`;
const Callframe = styled.div`
	width: 100%;
	height: 100%;
`;

export default VideoCall;
