/* eslint-disable jsx-a11y/anchor-is-valid */
import "../scss/main.scss";
import BlueLogoClearBackground from "../images/BlueLogoClearBackground.png";
import GrayHome from "./../images/listIcons/Gray Home.png";
import GrayCalendar from "./../images/listIcons/Gray Calendar.png";
import Messages from "./../images/listIcons/Messages.png";
import GrayFiles from "./../images/listIcons/Gray Files.png";
import GrayHistory from "./../images/listIcons/Gray History.png";
import GraySettings from "./../images/listIcons/Gray Settings.png";
import GraySearch from "./../images/listIcons/Gray Search.png";
import GrayMoney from "./../images/listIcons/Gray Money.png";
import personIcon from "./../images/personIcon.png";
import LogoutIcon from "./../images/logoutIcon.png";
import { useContext } from "react";
import StudentContext from "./../Contexts/StudentContext";
import config from "../utils/config";

export default function Sidebar() {
	const user = useContext(StudentContext);

	function logout() {
		if (window.confirm("Are you sure you would like to logout?")) {
			window.location.href = config.API_URL + "/logout";
		}
	}

	function smallNavExpand() {
		if (window.innerHeight <= 575 || window.innerWidth <= 575) {
			let dashList = document.getElementsByClassName(
				"dashList"
			)[0] as HTMLElement;

			console.log(dashList);

			if (
				dashList.style.marginBottom === "-79px" ||
				dashList.style.marginBottom === ""
			) {
				dashList.style.marginBottom = "0px";
			} else {
				dashList.style.marginBottom = "-79px";
			}
		}
	}

	return (
		<header>
			<div className="subNav">
				<div id="logo-background">
					<a href={config.API_URL}>
						<img
							id="nav-logo-dash"
							src={BlueLogoClearBackground}
							alt="Cloud Music Suite Logo"
							className="icon cursorPoint"
						/>
					</a>
				</div>
				<ul className="dashList">
					<li>
						<a href="/dashboard">
							{" "}
							<img
								src={GrayHome}
								className="icon cursorPoint"
								alt="Home Icon"
							/>
							<p>Home</p>
						</a>
					</li>
					<li>
						<a href="/calendar">
							<img
								src={GrayCalendar}
								className="icon cursorPoint"
								alt="Calendar Icon"
							/>
							<p>Calendar</p>
						</a>
					</li>

					<li className="smallListExpand cursorPoint" onClick={smallNavExpand}>
						<img src={BlueLogoClearBackground} alt="Cloud Music Suite Logo" />
					</li>

					<li>
						<a href="/message?isInstructorView=1">
							<img
								src={Messages}
								className="icon cursorPoint"
								alt="Messages Icon"
							/>
							<p>Messages</p>
						</a>
					</li>

					{!!user?.isInstructor === false && (
						<li className="">
							<a href="/instructors">
								<img
									src={GraySearch}
									className="icon cursorPoint"
									alt="Switch Account Icon"
								/>
								<p>Find Lesson</p>
							</a>
						</li>
					)}

					<li>
						<a href="/files">
							<img
								src={GrayFiles}
								className="icon cursorPoint"
								alt="Files Icon"
							/>
							<p>Files</p>
						</a>
					</li>

					<li>
						<a href="history">
							<img
								src={GrayHistory}
								className="icon cursorPoint"
								alt="History Icon"
							/>
							<p>History</p>
						</a>
					</li>
					<li>
						<a href="settings">
							<img
								src={GraySettings}
								className="icon cursorPoint"
								alt="Settings Icon"
							/>
							<p>Settings</p>
						</a>
					</li>
					{!!user?.isInstructor === true && (
						<li>
							<a
								href={config.API_URL + "/api/stripe/stripelink"}
								target="blank"
							>
								<img
									src={GrayMoney}
									className="icon cursorPoint"
									alt="Stripe Icon"
								/>
								<p>Stripe</p>
							</a>
						</li>
					)}
					{!!user?.isInstructor === true && (
						<li>
							<a href={"/instructors/" + user!.id}>
								<img
									src={personIcon}
									className="icon cursorPoint"
									alt="Switch Account Icon"
								/>
								<p>Student View</p>
							</a>
						</li>
					)}
					{user ? (
						<li>
							<a href="#" onClick={logout}>
								<img src={LogoutIcon} className="icon" alt="Logout Icon" />
								<p>Logout</p>
							</a>
						</li>
					) : (
						<li>
							<a href={config.API_URL + "/signIn"}>
								<img src={LogoutIcon} className="icon" alt="Login Icon" />
								<p>Login</p>
							</a>
						</li>
					)}

					<li className="closeNavButton">
						<a href="/#">
							<p>Close Menu</p>
						</a>
					</li>
				</ul>
			</div>
		</header>
	);
}
